module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Sentinel A","Sentinel B","Gotham SSm A","Gotham SSm B"],"urls":["https://cloud.typography.com/6759554/7358192/css/fonts.css"]},"typekit":{"id":"eff6rwn"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Office Space Guys","short_name":"OSG","start_url":"/","background_color":"#06BAD1","theme_color":"#06BAD1","display":"minimal-ui","icon":"src/images/osg-icon-ko-bg.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
